
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import router from '@/app/router';
import NameEnum from '@/app/router/NameEnum';
import PathEnum from '@/app/router/PathEnum';
import IProduct from '@/module/main/dto/IProduct';
import { getCulture, getCurrency } from "@/app/utils/EnvConfig";

const MOBILE_MAX_WIDTH = 575;

@Component({})
export default class ShopItem extends Vue {
  @Prop() private product!: IProduct;
  @Prop({ default: false }) private carouselCard?: boolean;

  private isDesktop: boolean = window.innerWidth > MOBILE_MAX_WIDTH;
  private zeroFlagSrc: string = 'zero-flag';
  private nameLength = this.product.name !== undefined
    ? this.product.name.length : 0;
  private totalPrice: string = this.$localize('total-price',
    this.getFormatedPrice(this.product.price));
  private monthlyPrice: string = this.$localize('per-month',
    this.getFormatedPrice(this.product.monthly));

  private get logo(): string {
    return this.$store.state.shops.find((img: any) => img.name === this.product?.seller)?.logo;
  }

  private get href(): string {
    return `${ PathEnum.PRODUCT.replace(':id', this.product.id.toString()) }`
  }

  private get name(): string {
    const productName = this.product.name;
    return this.nameLength > 50 ? `${ productName.substring(0, 40) }...` : productName;
  }

  @Watch('windowWidth')
  private onWidthChange(val: number): void {
    this.isDesktop = val > MOBILE_MAX_WIDTH;
  }

  private getFormatedPrice(price: number): string {
    return new Intl.NumberFormat(getCulture(), {
      currency: getCurrency(),
      style: 'currency',
      maximumSignificantDigits: 6,
    }).format(price);
  }

  private redirectToProduct(): void {
    if (this.product && !this.isDesktop) {
      router.push({ name: NameEnum.PRODUCT, params: { id: this.product.id.toString() } })
        .catch((err) => { console.log(''); });
      this.$forceUpdate();
      this.$emit('productRedirect', this.product.id);
    }
  }

  private setLoadedProductImg(id: number, url: string): void {
    (this.$refs[`productImg${ id }`] as HTMLDivElement).style.backgroundImage=`url('${ url }'`;
  }
}
