
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import router from '@/app/router';
import Api from '@/module/api/service/Api';
import ShopItem from '@/components/ShopItem.vue';
import IProducts from '@/module/main/dto/IProducts';
import ShopItemGroup from '@/components/ShopItemGroup.vue';
import RouteName from '../app/router/NameEnum';

@Component({
  components: {
    ShopItemGroup,
    ShopItem,
  },
})
export default class SearchResults extends Vue {
  @Prop() private results?: IProducts;
  @Prop() private query = '';

  private resultsReady = false;
  private currentLoadedPage = 0;
  private routeName = RouteName;
  private foundProducts?: IProducts;

  @Watch('results')
  private onResultsChange(val: IProducts): void {
    this.foundProducts = val;
    this.currentLoadedPage = 1;
    this.resultsReady = true;
    router.push({ name: this.routeName.SEARCH, params: { query: this.query } })
      .catch(() => { console.log(''); });
  }

  private loadPage(type: string): void {
    this.$emit('setLoading', true);
    this.currentLoadedPage = type === 'previous'
      ? (this.currentLoadedPage - 1) : (this.currentLoadedPage + 1);
    Api.search(this.query, this.currentLoadedPage)
      .then((response) => {
        if (response) {
          this.foundProducts = response;
          this.foundProducts.currentPage = this.currentLoadedPage;
          this.$forceUpdate();
          window.scrollTo(0, 100);
          this.$emit('setLoading', false);
        }
      });
  }

  private redirectToProduct(productId: number): void {
    this.$emit('productRedirect', productId);
  }
}
