
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ICategory from '@/module/main/dto/ICategory';
import router from '@/app/router';
import RouteName from '../app/router/NameEnum';
import categoryIconList from '@/module/main/service/CategoryIconList';
import ICategoryIcon from '@/module/main/service/ICategoryIcon';
import CategoriesModel from '@/module/main/model/CategoriesModel';
import ICategories from '@/module/main/dto/ICategories';

@Component
export default class MainNavBar extends Vue {
  private routeName = RouteName;
  private activeIndex = Number(this.$route.params.id);
  private activeDropdown = false;
  private searchQuery = '';
  private dropdownIDs: number[] = this.categories?.getRestIds() || [];
  private toggleNavbar: boolean = true;
  private lastWindowPosition: number = 0;

  public get categories(): ICategories {
    return this.$store.state.categories ?? new CategoriesModel([]);
  }

  public get mainCategories(): ICategory[] {
    return this.categories.getAll()?.filter(this.isMainCategory) ?? [];
  }

  private toggleSubcategories(id: number): void {
    ((this.$refs[`parentCategory${id}`]as any)[0] as HTMLDivElement)
      .classList.toggle('hide-subcategories');
  }

  private getCategoryIcon(categoryText: string): ICategoryIcon | null {
    const icon: ICategoryIcon[] = categoryIconList.filter(
      e => e.categoryNameCzech === categoryText.toLowerCase()
        || e.categoryNameSlovak === categoryText.toLowerCase());
    return icon ? icon[0] : null;
  }

  private isMainCategory(category: ICategory): boolean {
    return typeof category.parentCategoryId !== 'number'
      &&  (typeof category.types === 'undefined' || !category.types.includes('PROMO'));
  }

  private redirectToCategory(category: ICategory): void {
    this.activeIndex = category.id;
    this.searchQuery = '';
    if (this.dropdownIDs.indexOf(category.id) !== -1) {
      this.activeDropdown = true;
    } else {
      this.activeDropdown = false;
    }
    this.$emit('search', false, this.searchQuery);
    router.push({ name: this.routeName.CATEGORY, params: { id: category.id.toString() } })
      .catch(() => { console.log(''); });
    this.$emit('category-data', category, false);
    this.$emit('is-category-data', true);
  }

  private redirectToHomePage(): void {
    this.activeDropdown = false;
    this.activeIndex = this.categories.getPriority().id;
    router.push({ name: this.routeName.MAIN }).catch(() => console.log(''));
    this.searchQuery = '';
    this.$emit('search', false, this.searchQuery);
    this.$emit('category-data', this.categories.getPriority(), true);
    this.$emit('is-category-data', true);
  }

  private searchInput(): void {
    if (this.searchQuery.length >= 3) {
      this.$emit('search', true, this.searchQuery);
    } else {
      this.$emit('search', false, this.searchQuery);
    }
  }

  private toggleNavBar(): void {
    const position = window.pageYOffset || document.documentElement.scrollTop;
    this.toggleNavbar = position <= this.lastWindowPosition;
    this.lastWindowPosition = position <= 0 ? 0 : position;
  }

  public created(): void {
    this.$store.dispatch('getCategories').then(this.handleCategories);
    window.addEventListener('scroll', this.toggleNavBar);
  }

  private handleCategories(): void {
    const categories: CategoriesModel | undefined = this.$store.state.categories;

    if (!categories) {
      this.$emit('category-data', null, true);
      return;
    }
    
    this.dropdownIDs = categories.getRestIds();
    if (this.$route.params.id) {
      const categoryId: number = Number(this.$route.params.id);
      this.activeDropdown = this.dropdownIDs.indexOf(categoryId) !== -1;
      this.$emit('category-data', categories.getCategory(categoryId), false);
    } else {
      this.$emit('category-data', categories.getPriority(), true);
    }
    this.$emit('is-category-data', true);
  }
}
