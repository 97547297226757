const categoryIconList = [
  {
    iconName: 'recommended',
    categoryNameSlovak: 'odporúčame',
    categoryNameCzech: 'doporučujeme',
  },
  {
    iconName: 'mobiles',
    categoryNameSlovak: 'mobily',
    categoryNameCzech: 'mobily',
  },
  {
    iconName: 'computersAndOffice',
    categoryNameSlovak: 'počítače a kancelária',
    categoryNameCzech: 'počítače a kancelář',
  },
  {
    iconName: 'tv-audio-foto',
    categoryNameSlovak: 'tv, audio, foto',
    categoryNameCzech: 'tv, audio, foto',
  },
  {
    iconName: 'smartAndGaming',
    categoryNameSlovak: 'smart and gaming',
    categoryNameCzech: 'smart and gaming',
  },
  {
    iconName: 'appliances',
    categoryNameSlovak: 'spotrebiče',
    categoryNameCzech: 'spotřebiče',
  },
  {
    iconName: 'sportAndOutdoor',
    categoryNameSlovak: 'šport a outdoor',
    categoryNameCzech: 'sport a outdoor',
  },
  {
    iconName: 'furniture',
    categoryNameSlovak: 'nábytok',
    categoryNameCzech: 'nábytek',
  },
  {
    iconName: 'hobby-homeAndGarden',
    categoryNameSlovak: 'hobby, dom a záhrada, auto-moto',
    categoryNameCzech: 'hobby, dům a zahrada, auto-moto',
  },
  {
    iconName: 'toys',
    categoryNameSlovak: 'hračky',
    categoryNameCzech: 'hračky',
  },
  {
    iconName: 'other',
    categoryNameSlovak: 'ostatné',
    categoryNameCzech: 'ostatní',
  }
];

export default categoryIconList;
