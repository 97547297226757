
import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class BannerSlider extends Vue {
  @Prop({ type: Boolean, default: false }) private displayBanner!: boolean;
  @Prop({ type: Boolean, default: false }) private hideBanner!: boolean;
  @Prop() windowWidth?: number;

  private slide = 0;
  private sliding = false;
  private isDesktop = window.innerWidth > 575;

  private get banners(): any[] {
    return this.$store.state.banners;
  }

  @Watch('windowWidth')
  private onWidthChange(val: number): void {
    this.isDesktop = val > 575;
  }

  private onSlideStart(): void {
    this.sliding = true;
  }

  private onSlideEnd(): void {
    this.sliding = false;
  }

  private redirect(redirectUrl: string): void {
    if (typeof redirectUrl !== 'undefined') location.href = redirectUrl;
  }
}
