
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import IProducts from '@/module/main/dto/IProducts';
import IProduct from '@/module/main/dto/IProduct';
import ShopItem from './ShopItem.vue';

@Component({
  components: {
    ShopItem,
  },
})
export default class ShopItemGroup extends Vue {
  @Prop() private data!: IProducts;
  @Prop() private categoryId!: number;

  private isLoading = true;
  public products?: IProduct[] = this.data?.data;

  @Watch('data')
  onDataChange(val: IProducts) {
    this.isLoading = true;
    this.products = val.data;
    this.isLoading = false;
  }
}
