
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import MainNavBar from '@/components/MainNavBar.vue';
import MainFooter from '@/components/MainFooter.vue';
import BannerSlider from '@/components/BannerSlider.vue';
import ICategory from '@/module/main/dto/ICategory';
import Api from '@/module/api/service/Api';
import IProducts from '@/module/main/dto/IProducts';
import IBreadcrumb from '@/module/main/model/IBreadcrumb';
import { getTypingInterval } from "@/app/utils/EnvConfig";

@Component({
  components: {
    BannerSlider,
    MainFooter,
    MainNavBar,
  },
})
export default class HomeLayout extends Vue {
  @Prop({ type: Boolean, default: false }) private displayBanner!: boolean;
  @Prop({ type: Boolean, default: true }) private displayBreadcrumb!: boolean;
  @Prop() private showLoadingOverlay?: boolean;
  @Prop({ type: Boolean, default: true }) private mainFooterVersion!: boolean;

  private timerId!: number;
  private windowWidth = window.innerWidth;

  private hideBanner = false;

  private get breadcrumbItems(): IBreadcrumb[] {
    return this.$store.state.breadcrumbs;
  }

  private collectCategoryData(category: ICategory, banner: boolean): void {
    this.$emit('category-data', category, banner);
  }

  private async collectSearchQuery(searching: boolean, query: string): Promise<void> {
    this.hideBanner = searching;
    this.$emit('loadingLayout', true);
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    if (searching) {
      this.timerId = setTimeout(() => {
        Api.search(query, 1).then((response) => {
          this.receivedSearchResults(searching, response, query);
        });
      }, getTypingInterval());
    } else {
      this.$emit('searchResults', searching, null, query);
    }
  }

  private receivedSearchResults(searching: boolean, response: IProducts | null, query: string): void {
    this.$emit('searchResults', searching, response, query);
    this.$emit('loadingLayout', false);
  }

  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  }

  created() {
    if (this.$route.params.query) {
      this.collectSearchQuery(true, this.$route.params.query);
    }
  }
}
