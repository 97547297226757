
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class MainFooter extends Vue {
  @Prop({ type: Boolean, default: true }) private mainVersion!: boolean;

  private getCurrentYear(): number {
    return new Date().getFullYear();
  }
}
